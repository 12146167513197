import Vue, { PropType } from 'vue'
import copy from 'copy-to-clipboard'
import shareableMixin from '@/mixins/shareableMixin'
import { mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  mixins: [shareableMixin],
  props: {
    onSocialItemClick: Function,
    name: String,
    slug: String,
    id: Number,
    type: String as PropType<'job'|'gig'>
  },
  data () {
    return {
      hashtags: [
        'CryptoFreelance',
        'CryptoJobs',
        'CryptoJob',
        'BlockchainJob',
      ],
      copied: false,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    isJob () {
      return this.type === 'job'
    },
    typeTitle () {
      return this.isJob ? 'Job' : 'Gig'
    },
    shareUrl () {
      const ref = this.userId || this.$route.query.ref
      return `${process.env.VUE_APP_FRONTEND_URL}/${this.isJob ? 'jobs' : 'gigs'}/${this.slug}-${this.id}${ref ? `?ref=${ref}` : ''}`
    },
    facebookLink () {
      let text = `Check out my ${this.typeTitle} on LaborX: ${this.name}`
      const hashtag = this.hashtags[0]
      // eslint-disable-next-line
      return `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_FACEBOOK_APP_ID}&href=${this.shareUrl}&display=popup&quote=${text}&hashtag=%23${hashtag}&redirect_uri=${this.shareUrl}`
    },
    twitterLink () {
      const text = `Check out my ${this.typeTitle} on LaborX: ${encodeURIComponent(this.name)} %0A%0AApply now ⬇️%0A${this.shareUrl} %0A%0A`
      const hashtags = this.hashtags.join(',')
      return `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}`
    },
    telegramLink () {
      let text = `Check out my ${this.typeTitle} on LaborX: ${this.name}`
      return `https://telegram.me/share/url?url=${this.shareUrl}&text=${text}`
    },
    linkedinLink () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.shareUrl}`
    },
    links () {
      return {
        facebook: this.facebookLink,
        twitter: this.twitterLink,
        telegram: this.telegramLink,
        linkedin: this.linkedinLink,
      }
    },
  },
  methods: {
    copyUrlToClipboard () {
      copy(this.shareUrl)
      this.copied = true
    },
    onHide () {
      setTimeout(() => {
        this.copied = false
      }, 500)
    },
  }
})
